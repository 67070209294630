.justify{
    text-align: justify;
}
.footer{
    background-color: #202027
}
.sc-bczRLJ.fUrBzq {
    background:  #1b1a21;
  }
  .text-center.fs-5.mb-4 {
    color: white;
  }
  .headingBlock {
    color: white;
  }
  .form-label {
    color: white;
  }
  h4 {
    color: rgb(0, 0, 0);
  }
  h5 {
    color: white;
  }
  .text-primary.fw-bold.form-label {
    color: #ffffff !important;
  }


  .form-row.passwordValid {
    background: #1B1A21;
  }
.footer div{
    max-width: 1200px;
    color: #ffffff;
    margin: 0 auto;
    padding: 70px 30px;
    text-align: justify;
    bottom: 0px;
    background-color: #202027;
    /*position: sticky;*/
}
.form-holder h4{
  color: white;
}
.header{
    box-shadow: 1px 1px 1px 1px #1b1a21;
    padding: 20px;
    position: sticky;
    z-index: 9;
    top: 0;
    background-color: #1b1a21;
}

.passwordValid{
    box-shadow: 8px 8px 1px 0px #806BFF;
    padding: 20px;
    border: 1px solid #806BFF;
}
.valid,.invalid{
    margin-bottom: 0px;
}
.w_100{
    width: 100%;
}
.ml_0{
    margin-left: 0px !important;
}
.dropbtn{
    display: flex;
    justify-content: space-between;
}
@media (max-width: 767px){
    .row .primary{
        width: 100%;
        padding: 0px 24px;
    }
    .phoneNum{
        margin-bottom: 30px;
    }
    .col.buttonWidth {
        width: 150px;
        margin: 10px;
      }
}

.loaders, #global-loader1 {
    position: fixed;
    top: 50%; /* Center the loader vertically */
    left: 50%; /* Center the loader horizontally */
    transform: translate(-50%, -50%); /* Center the loader perfectly */
    z-index: 99;
    width: 100%;
    height: 100%; /* Make the loader cover the entire viewport */
    background: #2752a073; /* Semi-transparent background */
}

.loaders img, #global-loader1 img {
    position: absolute; /* Position the loader image absolutely */
    top: 50%; /* Center the image vertically */
    left: 50%; /* Center the image horizontally */
    width: 100px;
    transform: translate(-50%, -50%); /* Center the image perfectly */
    border-radius: 15px;
}
.valid{
    color: #806BFF;
}
.invalid{
    color: #ffffff;
}

.form-control{
  color: #ffffff !important;
  background-color: #1B1A21 !important;
  border: 2px solid; /* Set a border width */
  border-image: linear-gradient(90deg, #A65C9E 0%, #D85C81 48%, #D78351 100%);
  border-image-slice: 1; /* This will use the full gradient as the border */
  transition: all 0.5s ease;
}
.form-control::placeholder {
  color: rgb(138, 137, 137);
  opacity: 1;
}

.input-group input::placeholder {
  color: rgb(138, 137, 137);
}
.form-control.is-valid{
  border: 2px solid; /* Set a border width */
  border-image: linear-gradient(90deg, #A65C9E 0%, #D85C81 48%, #D78351 100%);
  border-image-slice: 1; /* This will use the full gradient as the border */
  transition: all 0.5s ease;
}

.form-select{
  border: 2px solid; /* Set a border width */
  border-image: linear-gradient(90deg, #A65C9E 0%, #D85C81 48%, #D78351 100%);
  border-image-slice: 1; /* This will use the full gradient as the border */
  transition: all 0.5s ease;
  color: #FFFFFF !important;
  background-color: #1B1A21 !important;
}

.backgroundColor {
    background-color: rgba(255, 255, 255, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-items: center;
    font-family: 'outfitLight', sans-serif;

}

.cardContainer {
    width: 380px;
    height: 200px;
    background-color: white;
    border-radius: 15px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inputField {
    margin-top: 8px;
    width: 280px;
    height: 40px;
}

.selected, .arrow, .up {
  display: none;
}

.selected, .country-list {
    display: none;
}

#formStyle {
  max-width:1256px;
  padding:0px 20px 70px 20px;
  margin:0 auto;
  background: #29272F;
}

#submitbuttonstyle{
  background: linear-gradient(90deg, #9E4CF8 0%, #816AFF 100%) !important;
  border-radius: 25px;
  height: 50px;
}
